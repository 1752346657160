




































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  data: () => ({
    result: []
  }),
  methods: {
    async getParkings(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_share/smart_park_light/stores`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.result = res.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getParkings();
  }
});

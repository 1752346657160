






































































































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

import TitleBar from '@//components/Examination/TitleBar/index.vue';
import SQL01 from '@/components/Examination/SqlSelect/SQL01.vue';
import SQL02 from '@/components/Examination/SqlSelect/SQL02.vue';
import SQL03 from '@/components/Examination/SqlSelect/SQL03.vue';
import SQL04 from '@/components/Examination/SqlSelect/SQL04.vue';
import SQL05 from '@/components/Examination/SqlSelect/SQL05.vue';
import SQL06 from '@/components/Examination/SqlSelect/SQL06.vue';
import SQL07 from '@/components/Examination/SqlSelect/SQL07.vue';
import SQL08 from '@/components/Examination/SqlSelect/SQL08.vue';
import SQL09 from '@/components/Examination/SqlSelect/SQL09.vue';
import SQL10 from '@/components/Examination/SqlSelect/SQL10.vue';
import SQL11 from '@/components/Examination/SqlSelect/SQL11.vue';
import SQL12 from '@/components/Examination/SqlSelect/SQL12.vue';
import SQL13 from '@/components/Examination/SqlSelect/SQL13.vue';
import SQL14 from '@/components/Examination/SqlSelect/SQL14.vue';
import SQL15 from '@/components/Examination/SqlSelect/SQL15.vue';
import SQL16 from '@/components/Examination/SqlSelect/SQL16.vue';
import SQL17 from '@/components/Examination/SqlSelect/SQL17.vue';
import SQL18 from '@/components/Examination/SqlSelect/SQL18.vue';
import SQL19 from '@/components/Examination/SqlSelect/SQL19.vue';
import SQL20 from '@/components/Examination/SqlSelect/SQL20.vue';
import SQL21 from '@/components/Examination/SqlSelect/SQL21.vue';

interface SelectSql {
  servers:       any[];
  selectParking: number | null;
  menuTitle: string;
}

export default Vue.extend({

  name: "select-sql",

  data(): SelectSql {
    return {
      servers:       [],
      selectParking: null,
      menuTitle: "選択式SQL調査",
    }
  },

  components: {
    TitleBar,
    SQL01,
    SQL02,
    SQL03,
    SQL04,
    SQL05,
    SQL06,
    SQL07,
    SQL08,
    SQL09,
    SQL10,
    SQL11,
    SQL12,
    SQL13,
    SQL14,
    SQL15,
    SQL16,
    SQL17,
    SQL18,
    SQL19,
    SQL20,
    SQL21,
  },

  methods: {
    // 検索フォームのセレクトボックス用の物件情報一覧
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      return await axios
      .get(url)
      .then(res => {
        this.servers = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
  },

  mounted() {
    this.getServerInfo();
  }

});

<template>
  <div id="app">
    <v-app>
      <Header v-if="!isPopupRoute"></Header>
      <div class="layout-wrapper">
        <div class="hidden-sm-and-down" v-if="!isPopupRoute">
          <SideMenu class="sidebar-area"></SideMenu>
        </div>
        <div class="contents-wrapper">
          <router-view />
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import Header from "./components/layouts/Header.vue";
import SideMenu from "./components/layouts/SideMenu.vue";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    SideMenu
  },
  computed: {
    isPopupRoute() {
      return this.$route.meta.isPopup === true
    }
  }
});
</script>

<style lang="scss">
.layout-wrapper {
  margin-top: 48px;
  height:100%;
}

.sidebar-area {
  position: fixed !important;
  left: 0;
  height: 100%;
  border-radius: 0px !important;
}

.contents-wrapper { margin: 0 0 0 200px;}

@media screen and (max-width: 960px) {
  .contents-wrapper { width: 100%; margin: 0; padding: 0;}
}

th { text-align: left; }

.search-col { margin-left: auto; }
</style>

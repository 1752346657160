
























































































































































  import Vue from "vue";
  import axios from "axios";
  import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"

  export default Vue.extend({
    data() {
      return {
        parkingid: null,
        entrntNo: null,
        entrntTime: null,
        exitTime: null,
        parkingName: null,
        parkingTime: null,
        payTotal: null,
        place: null,
        classNumber: null,
        kana: null,
        carNumber: null,
        result:{},
        headers: [
          { text: '精算機', value: 'NAME' },
          { text: 'parking_id', value: 'PARKING_ID' },
          { text: '実行時間', value: 'journalTime' },
          { text: '操作種別', value: 'SHORT_LABEL' },
          { text: 'パラメーター', value: 'PARAMATER' }
        ],
        mobileHeaders: [
          { text: 'parking_id', value: 'parking_id' },
          { text: '実行時間', value: 'createdAt' },
          { text: '操作種別', value: 'operation_name' },
          { text: 'パラメーター', value: 'parameter' },
          { text: 'account_mail', value: 'mail_address' },
        ],
      };
    },

    filters: {
      formatTime(minutes: number) {
        if (!minutes || isNaN(minutes)) return '0分';
        const days = Math.floor(minutes / 1440); // 1日 = 1440分
        const hours = Math.floor((minutes % 1440) / 60);
        const remaining = minutes % 60;
        const parts = [];
        if (days > 0) parts.push(`${days}日`);
        if (hours > 0 || days > 0) parts.push(`${hours}時間`);
        parts.push(`${remaining}分`);

        return parts.join('');
      }
    },

    components:{
      ObjectToBacklog
    },

    methods: {
      async operationJournalOpen(): Promise<void>{
        this.$vloading.show();
        this.result = {};
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/operation_show`;
        const formData = { params: {
          id:          this.$route.query.id,
          parkingId:   this.$route.query.parkingId,
          entrntNo:    this.$route.query.entrntNo,
          entrntTime:  this.$route.query.entrntTime,
          exitTime:    this.$route.query.exitTime,
          payTotal:    this.$route.query.payTotal,
          place:       this.$route.query.place,
          classNumber: this.$route.query.classNumber,
          kana:        this.$route.query.kana,
          carNumber:   this.$route.query.carNumber,
        } };
        return await axios
        .get(url, formData)
        .then(res => {
          console.log(res.data)
          this.result = res.data
        })
        .catch(error => {
          console.log(error)
          alert("精算機情報の取得に失敗しました");
        })
        .finally(() => {
          this.$vloading.hide();
        });
      },
    },

    mounted() {
      this.parkingid   = this.$route.query.parkingId;
      this.entrntNo    = this.$route.query.entrntNo;
      this.entrntTime  = this.$route.query.entrntTime;
      this.exitTime    = this.$route.query.exitTime;
      this.parkingName = this.$route.query.parkingName;
      this.parkingTime = this.$route.query.parkingTime;
      this.payTotal    = this.$route.query.payTotal;
      this.place       = this.$route.query.place;
      this.classNumber = this.$route.query.classNumber;
      this.kana        = this.$route.query.kana;
      this.carNumber   = this.$route.query.carNumber;
      this.operationJournalOpen();
    }

});








































































































































































import Vue from "vue";
import axios from "axios";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"
import ObjectToDeletePayment from "@/components/Show/ObjectToDeletePayment.vue"
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";

interface Params {
  headers: any[];
  selectObj: any[];
  dialog: boolean;
  show: any[];
  successCarNumber:string;
}
export default Vue.extend({

  components:{
    VehicleInformations,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel,
    ObjectToDeletePayment
  },

  data(): Params {
    return {
      successCarNumber:"",
      show: [],
      dialog: false,
      headers: [
        { text: '入庫', value: 'entrantTime' },
        { text: '出庫', value: 'exitTime' },
        { text: '利用時間', value: 'parkingTime' },
        { text: 'ナンバー', value: 'fullNumber' },
        { text: '状態', value: 'vehicleStatusId' },
        { text: '請求取り消し', value: 'delete' },
        { text: '操作記録', value: 'logStatusFixFlg' },
        { text: '画像', value: 'image' }
      ],
      selectObj: [],
    }
  },

  methods: {
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        console.log(error);
        alert("履歴の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    reload() {
      const number = this.successCarNumber.split(" ");
      this.$emit('reload', number[0], number[1], number[2], number[3]);
    },
  },
  props:{
    result:{},
    selectServer:{}
  }
});

































import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToDeletePayment from "@/components/Show/ObjectToDeletePayment.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({
  components:{
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToDeletePayment
  },
  watch: {
    // 選択中の削除リストのリセット
    UnpaidList(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectObj = [];
      }
    }
  },
  data(): Params {
    return {
      headers: [
        { text: '入庫日時', value: "entrantTime" },
        { text: '出庫日時', value: "exitTime" },
        { text: '駐車場名', value: "parkingName" },
        { text: '請求金額', value: "payArrears" },
        { text: '支払い済金額', value: "paidArrears" },
      ],
      selectObj: [],
    }
  },
  props:{
    UnpaidList:{},
    selectServer:{},
    successCarNumber:{}
  },
  methods: {
    reload() {
      this.selectObj = [];
      this.$emit('reload');
    }
  },
});

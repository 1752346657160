






































import Vue from "vue";
interface Params {
  menu: any;
}
export default Vue.extend({
  data(): Params {
    return {
      menu:[
        {
          title:"全プロダクト",
          icon:"mdi-cube-outline",
          menu:[
            {title: "集計", path: "", disabled: true  }
          ]
        },
        {
          title:"スマートパーク",
          icon:"mdi-cube-outline",
          menu:[
            {title: "新未払い共有の稼働前タスク", path: "/UnpaidShare/pre-production-tasks", disabled: false },
          ]
        },
        {
          title:"スマートパークライト",
          icon:"mdi-cube-outline",
          menu:[
            {title: "物件情報", path: "/UnpaidShare/SmartParkLight/store-info", disabled: false },
          ]
        }
      ]
    }
  },
})

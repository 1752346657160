



































































import Vue from "vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToDeletePayment from "@/components/Show/ObjectToDeletePayment.vue"

interface Params {
  headers: any[];
  selectObj: any[];
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToDeletePayment
  },

  data(): Params {
    return {
      headers: [
        { text: '物件名', value: "parkingName" },
        { text: 'P_ID', value: "parkingId" },
        { text: '状態', value: "vehicleStatusId" },
        { text: '精算機操作', value: "payOperationFlg" },
        { text: '請求取り消し', value: 'delete' },
        { text: '入庫時間', value: "entrantTime" },
        { text: '出庫時間', value: "exitTime" },
        { text: '利用時間', value: "parkingTime" },
        { text: '請求金額', value: "accountTotal" },
        { text: 'サービス券利用', value: "payMethodsFlg" },
        { text: '現金', value: "payCash" },
        { text: '電子マネー/IC', value: "payIcCard" },
        { text: 'credit', value: "payCredit" },
        { text: 'QR', value: "payQr" },
        { text: '未払金額', value: "payArrears" },
        { text: '削除記録', value: "deleteFlg" },
        { text: '支払金額', value: "payTotal" }
      ],
      selectObj: [],
    }
  },
  methods: {
    reload() {
      this.$emit('reload');
    },

    operationJournalOpen(id:any, no:any, time:any, name:any, eixt:any, pTime:any, aTotal:any, pTotal:any){
      const number = this.successCarNumber.split(" ");
      const routeData = this.$router.resolve({
        name: 'LocalOperationJournal',
        query: {
          id: this.selectServer,
          parkingId: id,
          entrntNo: no,
          entrntTime: time,
          exitTime: eixt,
          parkingTime: pTime,
          parkingName: name,
          payTotal: pTotal,
          place: number[0],
          classNumber: number[1],
          kana: number[2],
          carNumber: number[3]
        }
      });
      const windowFeatures = 'width=1000,height=700,top=100,left=100,resizable=yes,scrollbars=yes';
      window.open(routeData.href, '_blank', windowFeatures);
    },
  },
  props:{
    UsageHistoryList:{},
    successCarNumber:{},
    selectServer:{}
  }
});

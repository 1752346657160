













































































































































































































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";

export default Vue.extend({
  data: () => ({
    settings: []
  }),
  methods: {

    async getSettings(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_share/smart_park/pre_production_tasks`;
      return await axios
      .get(url, { params: { SERVER_ID: this.selectServer } } )
      .then(res => {
        console.log(res);
        this.settings = res.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },

    async registerRequestParkingId(id:number): Promise<void> {
      this.$vloading.show();
      if (!window.confirm('Localのtbl_settings["unpaid_request_parking_id"]の値を追加します。')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_share/smart_park/pre_production_tasks/register_request_parking_id`;
      return await axios
      .post(url, { SERVER_ID: id } )
      .then(res => {
        alert(res.data.message);
        if (res.data.success) {
          this.getSettings();
        }
        console.log(res);
      })
      .catch(error => {
        alert(error.data.message);
        console.log(error);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },

    async registerUnpaidShareStore(): Promise<void> {
      this.$vloading.show();
      if (!window.confirm('選択中の物件で新未払い共有データベースにデータを登録します')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/unpaid_share/smart_park/pre_production_tasks/register_unpaid_share_store`;
      return await axios
      .post(url, { SERVER_ID: this.selectServer } )
      .then(res => {
        console.log(res);
        this.getSettings();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },

  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSettings();
      }
    }
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    },
  }
});

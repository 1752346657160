<template>
  <v-container>
    <v-overlay :value="overlay"> <v-progress-circular indeterminate size="64" /> </v-overlay>

    <div v-if="view" class="overlay">
      <v-alert border="right" color="blue" prominent type="success">
        ユーザー情報を更新しました。 ５秒後にログイン画面に遷移します。
      </v-alert>
    </div>

    <v-card max-width="500" class="pa-3">
      <p class="text-center">
        <span class="text-h6">
          <v-icon color="orange" class="mb-1 mr-3">mdi-email</v-icon>
          <b>CommonsManagement 招待ユーザー登録</b>
        </span>
      </p>

      <v-row>
        <v-col class="align-center">
          <v-card class="ma-2 pa-3" outlined>
            <sapn class="text-caption"><b>1.名前の入力</b></sapn>
            <v-divider class="mb-4"></v-divider>
            <v-text-field
              outlined
              dense
              prepend-icon="mdi-account"
              label="氏"
              v-model="formData.familyName"
              :rules="familyNameRules"
              placeholder="【例】山田"
              ref="family_name"/>
            <v-text-field
            outlined
            dense
            prepend-icon="mdi-account"
            label="名"
            v-model="formData.firstName"
            :rules="firstNameRules"
            placeholder="【例】太郎"
            ref="first_name"/>
          </v-card>
          <v-card class="ma-2 pa-3" outlined>
            <sapn class="text-caption"><b>2.パスワードの作成</b></sapn>
            <v-divider class="mb-4"></v-divider>
            <v-text-field prepend-icon="mdi-identifier" label="ログインID ※変更できません" :value="id" disabled/>
            <v-text-field outlined prepend-icon="mdi-key" label="新しいパスワードを入力" 
            dense
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
              :type="show1 ? 'text' : 'password'" 
              v-model="formData.password" 
              :rules="passwordRules"
              @click:append="show1 = !show1"
              ref="password" />
            <v-text-field outlined prepend-icon="mdi-key" label="新しいパスワードを入力（確認）" 
              dense
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" 
              :type="show2 ? 'text' : 'password'" 
              v-model="formData.confirmPass" :rules="confirmPassRules" 
              @click:append="show2 = !show2"
              ref="confirm"/>
          </v-card>
          <v-card class="ma-2 pa-3 mb-10" outlined>
            <sapn class="text-caption"><b>3.所属部署の入力</b></sapn>
            <v-divider class="mb-4"></v-divider>
            <v-select outlined prepend-icon="mdi-account-group-outline" v-model="formData.department" :items="departments" label="所属部門" dense :rules="departmentRules" ref="departments"/>
          </v-card>
          <v-btn dark large block color="orange" @click="submit()">
            <v-icon>mdi-send</v-icon><b>登録を行う</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
  import axios  from "axios";
  import qs from 'qs';
  export default {
    name: 'changedPassword',
    data: function () {
      return {
        formData:{
          familyName: "",
          firstName: "",
          password: "",    // 新しいパスワード
          confirmPass: "", // 確認用パスワード
          department: "該当なし（未設定）"
        },
        id : this.$route.params.login_id,
        overlay: false,
        view: false,
        show1: false,
        show2: false,
        departments: [
          "該当なし（未設定）",
          "システム運営部",
          "システム開発部",
          "システム調整部",
          "営業部", 
          "大阪支店", 
          "情報システム部", 
          "メンテナンス部",
          "製品開発アルバイト", 
          "オペレーションGアルバイト",
          "稼働保守アルバイト"
        ],
        familyNameRules:[ v=> !v == "" || "姓が入力されていません" ],
        firstNameRules:[ v=> !v == "" || "名が入力されていません"],
        passwordRules:[
          v => !v =="" || "パスワードを入力して下さい",
          v => !/[^0-9a-zA-Z!?@#$%^&*()_+-={}']/.test(v) ||   "使用できない文字が含まれています 使用可能な文字は半角英数字と!@#$%^&*()_+-={}です",      // abcABCのみを制限,
          v => !/^([1-9]\d*|0)$/.test(v) || "8~16桁の英字、数字を組み合わせた値を設定してください" , // 数字のみを制限,
          v => /[^a-zA-Z]/.test(v) ||   "8~16桁の英字、数字を組み合わせた値を設定してください",      // abcABCのみを制限,
          v => v.length >= 8 || "文字数を8~16桁に増やしてください",  // 8文字未満の場合を制限
          v => v.length <= 16 || "文字数を8~16桁に減らしてください", // 16文字以上を制限
        ],
        confirmPassRules:[ 
          v => !v =="" || "パスワード（確認）を入力して下さい",
          v => v=== this.formData.password || "入力されたパスワードと一致しません" 
          ],
        departmentRules:[ v=> !v == "" || "部門を選択してください"],
      }
    },
    mounted(){
      this.$store.dispatch("gerUserSession")
      .then(()=>{
        const resetAt = JSON.parse(localStorage.user).reset_at;
        if (resetAt === undefined){
          location.href = '/invalid-link';
        }
      })
    },
    methods: {
      submit() {
        const f = this.formData
        if (f.familyName===""){ 
          this.$refs.family_name.focus();
        } else if (f.firstName===""){ 
          this.$refs.first_name.focus();
        } else if ( f.password ==="" ||
                    /[^0-9a-zA-Z!?@#$%^&*()_+-={}']/.test(f.password) ||
                    f.password.length < 8 || f.password.length > 16 ||
                    /^([1-9]\d*|0)$/.test(f.password) || !/[^a-zA-Z]/.test(f.password))
        { 
          this.$refs.password.focus();
        } else if (f.confirmPass==="" || f.confirmPass!==f.password){
          this.$refs.confirm.focus();
        } else if (f.department==="") {
          this.$refs.departments.focus();
        } else {
          if(confirm("以下の内容で登録してよろしいですか？")){
            this.send();
          }
        }
      },
      async send(){
        this.overlay = !this.overlay;
        const params = { userinfo: { realname: `${this.formData.familyName}` + ' '+ `${this.formData.firstName}`,
                         password: this.formData.password,
                         department: this.formData.department,
        }};
        await axios.post("/api/update_user_info",  qs.stringify(params))
        .then((res) => {
          console.log(res);
          this.overlay = !this.overlay;
          this.view = !this.view
          setTimeout(function(){
            const basePath = process.env.VUE_APP_FRONT_BASE_PATH;
            location.href=`${basePath}`; 
          }, 5*1000);
        })
        .catch((error)=>{
          console.log(error)
          this.overlay = !this.overlay;
        })
      }
    },
    
  }
</script>

<style scoped>
.overlay { 
  background: rgba(0, 0, 0, .8); 
  position: fixed; 
  width: 100%; 
  height: 100%; 
  left: 0; 
  top: 0; 
  right: 0; 
  bottom: 0; 
  z-index: 900; 
  transition: all .5s ease; 
}

.container{
  width:70%; 
  max-width:1000px;
}

.sub-page-title{
  margin:50px 0 50px 0;

} 
</style>
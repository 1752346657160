






























































































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
    selectServer:{},
    successCarNumber:{}
  },
  data() {
    return {
      loading: false,
      dialog: false,
      kindId: 1,
      reason:"",
      reqReason: "【コモンズ削除】" + JSON.parse(localStorage.user).name + " ",
      unpaidListHeader:[
        { text: '入庫', value: 'entrantTime' },
        { text: '出庫', value: 'exitTime' },
        { text: '駐車場名', value: 'parkingName' },
        { text: '請求金額', value: 'payArrears' }
      ],
    };
  },
  methods: {
    async deleteSubmit(): Promise<void> {
        if (!window.confirm('本当に削除しますか？')) return
        this.loading = true;
        // 選択したinout_noを配列に収める
        const inOutNoAry = []
        for (const v in this.selectObj) {
          inOutNoAry.push(this.selectObj[v]['inoutNo'])
        }
        const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_delete_payment`;
        const formData = {
          params: {
            server: this.selectServer,
            inoutNo: inOutNoAry,
            kindId: this.kindId,
            reason: this.reqReason + this.reason
          }
        };
        return await axios
        .post(url, formData.params)
        .then(res => {
          if (res.data.status != 500) {
            alert("削除しました");
            this.$emit('reload');
            this.dialog = false
          } else {
            alert("削除に失敗しました");
          }
          this.$vloading.hide();
        })
        .catch(error => {
          console.log(error)
          alert("更新に失敗しました");
          this.$vloading.hide();
        })
        .finally(() => {
          this.loading = false;
        });
      },
  },
});
